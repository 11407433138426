

















import { Component, Vue, Prop } from 'vue-property-decorator'
import Schema, { ErrorList } from 'async-validator'

@Component({
  name: 'nbFormItem',
  inject: ['form']
})
export default class extends Vue {
  [x: string]: any
  @Prop({ default: '' }) label!: string
  @Prop({ default: '' }) prop!: string
  error = ''

  mounted() {
    this.$on('validate', () => {
      this.validate()
    })
  }

  validate() {
    const rules = this.form.rules[this.prop]
    const value = this.form.model[this.prop]
    const schema = new Schema({ [this.prop]: rules })
    // 返回promise, 全局可以统一处理
    // @ts-ignore
    return schema.validate({ [this.prop]: value }, (errors: ErrorList) => {
      if (errors) {
        this.error = errors[0].message
      } else {
        this.error = ''
      }
    })
  }
}
