



















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import nbFormItem from './Form/nbFormItem.vue'
import nbForm from './Form/nbForm.vue'
import nbInput from './Form/nbInput.vue'
import { addUser, fetchUserList, updateListUserInfo } from '@/api/users'
import { UserInfo, roleManagerType } from '@/types/user'
import { UserModule } from '@/store/modules/user'
import { checkphone, checkEmail } from '@/utils/tools'

@Component({
  name: '',
  components: {
    nbFormItem,
    nbForm,
    nbInput
  }
})
export default class extends Vue {
  @Prop({ required: true }) param!: {type: string, show: boolean}
  userInfo:UserInfo = {
    mobile: '',
    name: '',
    email: '',
    positionName: '',
    roleManagerType: '1',
    username: ''
  }

  @Watch('param', { deep: true })
  watchParam(v: any) {
    console.log('vvv', v)
    if (v.type === 'edit' && v.data) {
      this.userInfo = v.data
      this.userInfo.roleManagerType = (String(v.data.roleManagerType) as roleManagerType)
    }
    if (v.type === 'create') {
      this.userInfo = {
        mobile: '',
        name: '',
        email: '',
        positionName: '',
        roleManagerType: '1',
        username: ''
      }
    }
  }

  rules = {
    name: [{ required: true, message: '请输入用户名' }],
    mobile: [{ required: true, message: '请输入手机号', tigger: 'change' }, { validator: checkphone, message: '请输入正确格式的手机号' }],
    email: [{ required: true, message: '请输入邮箱' }, { validator: checkEmail, message: '请输入正确格式的邮箱' }],
    positionName: [{ required: true, message: '请输入职位' }],
    roleManagerType: [{ required: true, message: '请选择权限' }]
  }

  typeObj ={
    create: '新增成员',
    edit: '修改成员信息',
  }
  dialogVisible = ''
  created() {

  }

  get userAuthArr() {
    const arr = []
    const obj = UserModule.userAuthObj
    for (const key in obj) {
      arr.push({
        value: key,
        // @ts-ignore
        label: obj[key]
      })
    }
    return arr
  }

  cancel() {
    this.$emit('update:param', {
      type: this.param.type,
      show: false
    })
  }

  confirm() {
    (this.$refs.userInfoForm as any).validate(async(isValid: any) => {
      console.log('isValid', isValid)
      if (isValid) {
        this.userInfo.username = this.userInfo.mobile
        if (this.param.type === 'create') {
          const res = await addUser(this.userInfo)
          if (res.statusCode === 0 && res.data) {
            this.$message({
              type: 'success',
              message: '添加成功'
            })
            this.$emit('update:param', {
              type: this.param.type,
              show: false
            });
            (this.$parent as any).fetchListPre()
          }
        }
        if (this.param.type === 'edit') {
          const res = await updateListUserInfo(this.userInfo)
          if (res.statusCode === 0 && res.data) {
            this.$message({
              type: 'success',
              message: '修改成功'
            })
            this.$emit('update:param', {
              type: this.param.type,
              show: false
            });
            (this.$parent as any).fetchList()
          }
        }
      }
    })
  }
}
