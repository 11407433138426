






import { Component, Vue, Prop, Provide } from 'vue-property-decorator'

@Component({
  name: 'nbForm',

})
export default class NbForm extends Vue {
  // props: model rules
  // validate()
  @Provide('form')
  tempThis = this
  @Prop({ required: true }) model!: object
  @Prop() rules!: object
  created() {

  }

  // 全局校验
  validate(cb: Function) {
    console.log('validate111')
    // 执行内部所有的formitem方法，统一处理结果
    // 将formItem 数组转换为promise 数组
    const tasks = this.$children.filter((item: any) => item.prop).map((item: any) => item.validate())
    Promise.all(tasks)
    // eslint-disable-next-line
      .then(() => cb(true))
      // eslint-disable-next-line
      .catch(() => cb(false))
  }
}
