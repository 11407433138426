











































































import { Component, Vue } from 'vue-property-decorator'
import moduleHeader from '@/components/moduleHeader/index.vue'
import userDialog from './userDialog.vue'
import { fetchUserList, userStatusHandle } from '@/api/users'
import Pagination from '@/components/Pagination/index.vue'
import { UserInfoParams } from '@/types/user'
import { UserModule } from '@/store/modules/user'

@Component({
  name: '',
  components: {
    moduleHeader,
    userDialog,
    Pagination,
  },
})
export default class extends Vue {
  diologObj = {
    type: 'create',
    show: false,
    data: {},
  };
  fetchParams = {
    page: 1,
    size: 10,
    roleManagerType: null,
    name: '',
    mobile: '',
    email: '',
  };

  inputValue = '';
  select: keyof UserInfoParams = 'name';
  total = 0;
  tableData = [];

  created() {
    this.fetchList()
  }

  fetchListPre() {
    this.fetchParams.page = 1
    this.fetchList()
  }

  get userAuthObj() {
    return UserModule.userAuthObj
  }

  async editName(data: any) {
    this.diologObj = {
      show: true,
      type: 'edit',
      data: Object.assign({}, data),
    }
  }

  async onSearch() {
    this.fetchParams.roleManagerType = null
    this.fetchParams.name = ''
    this.fetchParams.mobile = ''
    this.fetchParams.email = ''

    // @ts-ignore
    this.fetchParams[this.select] = this.inputValue
    this.fetchList()
  }

  async changePage(v: any) {
    console.log('page', v)
    this.$nextTick(() => {
      this.fetchParams.page = v
      this.fetchList()
    })
  }

  async changeUserStatus(data: any, flag: boolean) {
    const res = await userStatusHandle(data)
    if (res.statusCode === 0 && res.data) {
      this.$message({
        type: 'success',
        message: '操作成功',
      })
      this.fetchList()
    }
  }

  async fetchList() {
    // this.fetchParams.page = 0
    console.log('this.fetchParams+++', this.fetchParams)
    const res = await fetchUserList(this.fetchParams)
    if (res.statusCode === 0 && res.data) {
      const { content, totalElements } = res.data
      this.tableData = content
      this.total = totalElements
    }
    console.log('res---', res)
  }

  createUser() {
    this.diologObj = {
      show: true,
      type: 'create',
      data: {},
    }
  }
}
