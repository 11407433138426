















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'nbInput',
  inheritAttrs: false

})
export default class extends Vue {
  @Prop({ default: 'text' }) type!: string
  @Prop({ default: '' }) value!: string
  @Prop({ default: '' }) name!: string
  created() {

  }

  onInput(e:HTMLElement) {
    const v = (e as any).target.value
    this.$emit('input', v)
    // @ts-ignore
    this.$parent.$emit('validate')
    // this.$parent.validate()
  }
}
